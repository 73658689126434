<template>
	<section class="content">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-4 form-group">
						<label class="control-label font-weight-normal">Tanggal</label>
						<div class="form-control d-flex justify-content-between">{{ formatDate(form.tanggal) }}</div>
					</div>
					<div class="col-md-4 form-group">
						<label class="control-label font-weight-normal">Kurir</label>
						<div class="form-control">{{ form.kurir }}</div>
					</div>
					<div class="col-md-4 form-group">
						<label class="control-label font-weight-normal">Rekening</label>
						<div class="form-control">{{ form.rekening }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-sm-auto form-group d-flex">
						<label class="control-label font-weight-normal mb-0 d-flex align-items-center">Total Nilai</label>
					</div>
					<div class="col-sm-5 col-md-3 form-group">
						<div class="form-control">{{ formatCurrency(form.total) }}</div>
					</div>
				</div>
				<table class="table table-hover" ref="tblrekon">
					<thead>
						<tr>
							<th>Daftar AWB/Resi</th>
							<th>Nilai yang diupload</th>
						</tr>
					</thead>
					<tbody></tbody>
				</table>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { authFetch, createTable, formatCurrency } from '@/libs/hxcore';
import moment from "moment";

export default {
	name: 'DetailRekonKurir',
	data() {
		return {
			form: {
				tanggal: moment(),
				kurir: 'bosCOD',
				rekening: 'Bank',
				total: 0
			},
			formItem: [],
		}
	},
	created: function() {
		authFetch("/order/rekonsiliasi/reconciled_kurir/" + this.$route.params.id)
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.form.tanggal = js.tanggal;
				this.form.kurir = js.kurir;
				this.form.rekening = js.rekening;
				this.form.total = js.total;

				var data = {
					resi: js.resi.split(","),
					nilai: js.nilai.split(",")
				},
				obj = null;

				for (var i = 0; i < data.resi.length; i++) {
					obj = {};
					obj['resi'] = data.resi[i];
					obj['nilai'] = data.nilai[i];

					this.formItem.push(obj);
				}

				this.tbl.fnAddData(this.formItem);
			});

	},
	methods: {
		formatDate: function(date) {
			return moment(date).format("DD/MM/YYYY");
		},
		formatCurrency: function(number) {
			return 'Rp ' + formatCurrency(number);
		}
	},
	mounted() {
		const self = this;

		this.tbl = createTable(this.$refs.tblrekon, {
			serverSide: false,
			frame: false,
			lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
			displayLength: 10,
			lengthChange: true,
			filterBy: [0],
			columns: [
				{ data: "resi" },
				{ 
					data: "nilai",
					render: function (data, type, row, meta) {
						return self.formatCurrency(data);
					},
				},
			]
		});
	}
}
</script>